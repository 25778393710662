.phones ul li {
	margin: 10px auto;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.phones ul li a {
	font-size: 18px;
}
.phones ul li a:first-child {
	font-family: "Medium";
}
.phones ul li a:last-child:hover {
	text-decoration: underline;
}
@media (min-width: 576px) {
}
