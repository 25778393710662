.menu {
	flex-wrap: wrap;
	padding: 15px;
	z-index: 999;
}
.menu li a {
	font-size: 18px;
	letter-spacing: 1px;
	border-bottom: 2px solid transparent;
	padding-bottom: 6px;
}
.menu li a:hover {
	border-bottom: 2px solid #c93;
}
.button {
	font-size: 28px;
	font-weight: bold;
	background-color: transparent;
	border: 0px;
	height: 36px;
	display: flex;
	margin: 15px auto;
}
.menu {
	display: none;
}
nav {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;
}
.activem {
	display: flex;
	justify-content: center;
	flex-direction: column;
	position: absolute;
	top: 70px;
	background-color: #ffffff;
	z-index: 999;
	width: 100%;
}
.menu li {
	margin: 15px auto;
}
.disactive {
	position: absolute;
	top: -100%;
}

@media (min-width: 576px) {
	nav {
		flex-direction: row;
	}
	.menu {
		width: 100%;
		display: flex;
		justify-content: space-around;
	}
	.button {
		display: none;
	}
	.menu li {
		margin: 0px auto;
	}
}
@media (max-width: 576px) {
	.phones ul li:first-child {
		width: 90%;
		padding: 5px;
		position: fixed;
		bottom: 0px;
		background-color: #ffffff;
		z-index: 999;
	}
}
