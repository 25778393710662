.price-block {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 15px auto 30px;
}
.price-inblock {
	margin: 15px auto;
	max-width: 290px;
	position: relative;
}
.price-inblock div {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	border: 1px solid #333;
	background-color: #fafafa;
	font-size: 1.5rem;
	padding: 25px 15px;
	position: relative;
}
.price-inblock div a:hover .price {
	text-decoration: underline;
}
.price-inblock div p {
	font-size: 16px;
}
.price-inblock-after,
.price-inblock-before {
	position: absolute;
	right: -2px;
	background-color: #f1c361;
	color: #333;
	padding: 3px 15px;
	font-size: 16px;
	text-align: center;
	align-self: flex-end;
	z-index: 99;
}
.price-inblock-before {
	top: -2px;
	border-radius: 0px 0px 0px 10px;
	-webkit-border-radius: 0px 0px 0px 10px;
	-moz-border-radius: 0px 0px 0px 10px;
	-ms-border-radius: 0px 0px 0px 10px;
	-o-border-radius: 0px 0px 0px 10px;
}
.price-inblock-after {
	bottom: -2px;
	border-radius: 10px 0px 0px 0px;
	-webkit-border-radius: 10px 0px 0px 0px;
	-moz-border-radius: 10px 0px 0px 0px;
	-ms-border-radius: 10px 0px 0px 0px;
	-o-border-radius: 10px 0px 0px 0px;
}
.price {
	color: #f1c361;
	font-family: "Medium";
}
.price-inblock:last-child div {
	background-color: #333;
}
.price-inblock:last-child div a {
	color: #f1c361;
}
/* <span className="price-inblock-before">HOT!</span>   */
@media (min-width: 576px) {
	.price-inblock {
		max-width: 360px;
	}
}
