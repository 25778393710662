@font-face {
	font-family: "Regular";
	src: url(./fonts/Comfortaa-Regular.ttf);
}
@font-face {
	font-family: "Medium";
	src: url(./fonts/Comfortaa-Medium.ttf);
}
body {
	font-size: 16px;
	font-family: "Regular";
	color: #333;
}
a {
	color: #333;
	transition: all 1s;
	-webkit-transition: all 1s;
	-moz-transition: all 1s;
	-ms-transition: all 1s;
	-o-transition: all 1s;
}
a:hover,
a:active {
	color: #c93;
	text-decoration: none;
	outline: none;
	transition: all 1s;
	-webkit-transition: all 1s;
	-moz-transition: all 1s;
	-ms-transition: all 1s;
	-o-transition: all 1s;
}
ul,
ul li {
	margin: 0;
	padding: 0;
}
ul li {
	list-style: none;
}
h1,
h2,
h3,
h4,
h5 {
	font-family: "Medium";
}
.like-header {
	font-family: "Medium";
	text-align: center;
	padding-bottom: 30px;
	border-bottom: 1px solid #c6aa28;
	position: relative;
	width: 100%;
	margin-bottom: 30px;
}
.like-header:after {
	position: absolute;
	content: " ";
	left: 50%;
	bottom: -11px;
	height: 21px;
	width: 21px;
	background-color: #c6aa28;
	transform: translateX(-50%) rotate(45deg);
}
.form-button {
	justify-content: center;
	padding: 15px 45px;
	border: 3px solid #f1c361;
	background-color: #333;
	color: #ffffff;
	margin: 15px auto;
	display: flex;
	font-family: "Medium";
	letter-spacing: 1px;
	transition: all 1s;
	-webkit-transition: all 1s;
	-moz-transition: all 1s;
	-ms-transition: all 1s;
	-o-transition: all 1s;
}
.form-button:hover {
	border-color: #333;
	background-color: #f1c361;
	color: #333;
	transition: all 1s;
	-webkit-transition: all 1s;
	-moz-transition: all 1s;
	-ms-transition: all 1s;
	-o-transition: all 1s;
}
input {
	padding: 15px 25px;
	border: 3px solid #f1c361;
	background-color: #333;
	color: #ffffff;
	margin: 15px auto;
	display: flex;
	font-family: "Medium";
	letter-spacing: 1px;
	transition: all 1s;
	-webkit-transition: all 1s;
	-moz-transition: all 1s;
	-ms-transition: all 1s;
	-o-transition: all 1s;
	outline: none;
}
select {
	display: flex;
	font-family: "Regular";
	padding: 3px 25px 3px 3px;
	box-sizing: border-box;
	margin: 0;
	border: 1px solid #c93;
	border-radius: 0.5em;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
		linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
	background-repeat: no-repeat, repeat;
	background-position: right 0.7em top 50%, 0 0;
	background-size: 0.65em auto, 100%;
}
select:focus {
	border-color: #aaa;
	box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
	box-shadow: 0 0 0 3px -moz-mac-focusring;
	color: #222;
	outline: none;
}
.active {
	color: #c93;
}
@media (min-width: 576px) {
}
