.content-block {
	background: url(./body.jpg) center center no-repeat;
	background-color: black;
	background-attachment: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	min-height: 500px;
	padding: 30px 0;
	color: #ffffff;
	background-size: cover;
}
.content-block .container {
	background-color: rgba(0, 0, 0, 0.45);
}
.content-block form {
	margin: 30px auto;
}
input {
	width: 100%;
	max-width: 290px;
}
@media (min-width: 576px) {
	.content-block {
		background: url(./body.jpg) left no-repeat;
		background-attachment: fixed;
		background-color: black;
		background-size: contain;
	}
	input {
		width: auto;
	}
}
