header {
	padding: 15px 0;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid #c93;
}
header div {
	text-align: center;
}
.logotype {
	width: 100%;
	max-width: 150px;
	height: auto;
}
header div:nth-child(2) {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}
header div:nth-child(2) h1 {
	width: 100%;
}
header div:nth-child(2) span {
	color: #ffffff;
	font-size: 18px;
	font-family: "Medium";
	margin-top: 10px;
	padding: 15px;
	border-radius: 3px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-ms-border-radius: 3px;
	-o-border-radius: 3px;
	background-color: #f1c361;
	background-image: -webkit-linear-gradient(
		to bottom,
		#bf953f,
		#f1c361,
		#b38728
	);
	background-image: -moz-linear-gradient(to bottom, #bf953f, #f1c361, #b38728);
	background-image: -ms-linear-gradient(to bottom, #bf953f, #f1c361, #b38728);
	background-image: -o-linear-gradient(to bottom, #bf953f, #f1c361, #b38728);
	background-image: linear-gradient(to bottom, #bf953f, #f1c361, #b38728);
}
@media (min-width: 576px) {
	header {
		flex-direction: row;
		justify-content: space-between;
	}
}
