.first-screen {
	background: url(./main.jpg) center center no-repeat;
	background-attachment: fixed;
	background-size: cover;
	position: relative;
	height: 360px;
	text-align: center;
	color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
}
.first-screen div {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.first-screen div h2 {
	font-family: "Medium";
	font-size: 3.3rem;
	line-height: 1.11;
}
.line-svg {
	width: 290px;
	height: auto;
	margin-bottom: 15px;
}
@media (min-width: 576px) {
	.first-screen {
		height: calc(90vh - 220px);
	}
	.first-screen div h2 {
		font-size: 4rem;
	}
}
