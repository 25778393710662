.ladies {
	padding-top: 45px;
	padding-bottom: 30px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
}
.lady {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	text-align: center;
}
.lady img {
	width: 100%;
	height: auto;
	overflow: hidden;
	border-bottom: 6px solid #333;
}
.lady img:hover {
	border-color: #f1c361;
	transition: all 1s;
	-webkit-transition: all 1s;
	-moz-transition: all 1s;
	-ms-transition: all 1s;
	-o-transition: all 1s;
}
.lady figure {
	position: relative;
	overflow: hidden;
}
.lady figure p {
	position: absolute;
	bottom: 5px;

	transition: all 1s;
	-webkit-transition: all 1s;
	-moz-transition: all 1s;
	-ms-transition: all 1s;
	-o-transition: all 1s;
}
.lady figure:hover p {
	bottom: 10px;
	transition: all 1s;
	-webkit-transition: all 1s;
	-moz-transition: all 1s;
	-ms-transition: all 1s;
	-o-transition: all 1s;
}
.lady figcaption {
	font-size: 21px;
	padding-top: 6px;
}
@media (min-width: 576px) {
	.lady img {
		max-width: 400px;
	}
	.lady figure p {
		bottom: -200px;
		text-align: center;
		width: 100%;
	}
}
